import axios from 'axios';
import {  isTokenExpiredTrue } from '../core/localStorageService';
import { loginRequest } from '../authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from '..';
export const refreshAccessToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  try {
    const token = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    });
    // console.log(token)
    return token.accessToken;
  } catch (error) {
    if(error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenRedirect(loginRequest)
        .then((response: any) => {
          return response.accessToken;
        })
        .catch((err) => null);
    } else {
      console.log(error);
    }
  }
}
const timezoneOffset = (new Date()).getTimezoneOffset();
const Http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
Http.interceptors.request.use(
  async (config: any) => {
    const token = await refreshAccessToken();
    // console.log(token)
    if(token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      // console.log(token)
    }
    config.headers.timezoneoffset = timezoneOffset;
    return config;
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);
Http.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.name &&
      error.response.data.message.name === "TokenExpiredError"
    ) {
      document.dispatchEvent(isTokenExpiredTrue);
    }
    return Promise.reject(error);
  }
);
export default Http;
