export const customEventFun = (value: any) => {
  return new CustomEvent("loading", { detail: value });
};

export const tokenExpiredFun = (value: any) => {
  return new CustomEvent("tokenExpired", { detail: value });
};

export const tableDefaultColDef = {
  sortable: true,
  editable: false,
  filter: true,
  flex: 1,
  floatingFilter: true,
  suppressMenu: true,
}
