export const WHATSAPP_PREFIX = "whatsapp:+";
export const SMS_PREFIX = "+";
export const MAX_FILE_SIZE = 52428800;
export const VALID_FILE_FORMAT = ['png','jpeg','jpg','txt','pdf','xls','xlsx','doc','docx','csv','odt','gif','mp3'
,'wav','mp4','wmv','avi','mkv','mov'];
export const COPY_SUCCESS_MESSAGE = "Message copied.";
export const UNEXPECTED_ERROR_MESSAGE =
  "Something went wrong. Please try again.";
export const CONNECTION_ERROR_MESSAGE = "No internet connection.";
export const NOTIFICATION_TIMEOUT = 4000;
export const ERROR_MODAL_MESSAGES = {
  FILE_SIZE_INVALID: {
    title: "File Size Exceeded",
    description:
      "The uploaded file exceeded the maximum file size(50MB) that this application supports.",
  },
  FILE_FORMAT_INVALID: {
    title: "Invalid File Format",
    description:
      "You may only upload JPG, PNG, DOC and PDF files. Please ensure your file is in one of these formats.",
  },
  ADD_PARTICIPANT: {
    title: "Unable to add participant",
    description:
      "You don’t have permission to add participants to the conversation",
  },
  CHANGE_CONVERSATION_NAME: {
    title: "Unable to save Conversation name",
    description:
      "Only creators of the Conversation can edit the Conversation name.",
  },
};
export const CONVERSATION_MESSAGES = {
  CREATED: "Conversation created.",
  NAME_CHANGED: "Conversation name changed.",
  LEFT: "You left the conversation.",
};

export const PARTICIPANT_MESSAGES = {
  ADDED: "Participant added.",
  REMOVED: "Participant removed.",
};

export const CONVERSATION_PAGE_SIZE = 30;

export const NOTIFICATION_LEVEL = {
  DEFAULT: "default",
  MUTED: "muted",
};

export const SMIT_FIT_EMAIL_EXISTS = "Email is already exist";
export const SMIT_FIT_EMAIL_EXISTS_MESSAGE = "Email ID already exists, Unique email is needed to activate the program";
export const EMAIL_DOES_NOT_EXISTS = "Email for the user does not exist";
export const EMAIL_DOES_NOT_EXISTS_MESSAGE = "Program cannot be assigned because email is not available. A unique email is needed to activate the program.";
export const EMAIL_UPDATED_MESSAGE = "Email Updated Successfully. You can recommended now";
export const ERROR_WHILE_FETCHING_MESSAGE = "Error while fetching data ...";
export const ERROR_UNABLE_TO_CONNECT = "Unable to connect at the moment";

export const PARTNER = {
  SMIT_FIT: " | Smit.fit",
}; 
export const PROGRAM_ADHERENCE_PARTNERED_PROGRAM_MESSAGE="Partner Driven Program is Activated, Program Adherence Functions are Disabled";
export const NO_DATA_FOUND="No Data Found";
export const DISCARD_CHANGES_WARNING_MESSAGE="Do you really want to remove the changes made to the program ?";
export const SUCCESS = "success";
export const TYPEFORM_FILLED_BY = "Health Coach";
export const LIFESTYLE_SURVEY="Lifestyle Survey";
export const STRESS_SURVEY="Stress Survey";
export const OVERALL_MHI_STATUS = "Overall MHI Score"
export const NOT_AVAILABLE="NA";
export const SCHEDULE_CONSULTATION="Please schedule a consultation to call the user";
export const FEEDBACK_LINK_SHARED="Feedback link shared";
export const NO_ANY_SCHEDULE_CONSULTATION = "You do not have any scheduled consultations!";
export const NO_RECORD_FOUND = "No record found";
export const NO_CONSULTATION = "No consultation history available for this patient";
export const COMPLETED = "Completed";
export const EXISTS = "EXISTS";
export const DOES_NOT_EXIST = "DOES_NOT_EXIST";
export const NO_MAPPING_EXISTS = "User is no longer mapped";
export const NO_DATA_AVAILABLE = "No Data available";
export const MEASURE_YOUR_VITALS = "Measure your Vitals"
export const CUSTOM_TASK = 'Custom Task'
export const CONSULTATION = 'Consultation'
export const STEPS_TRACKER = 'Steps Tracker'
export const CONTENT_TASK = 'Content Task'