import { customEventFun, tokenExpiredFun } from "./constant";

export const url = process.env.REACT_APP_API_ENDPOINT;
export const chatUrl = process.env.REACT_APP_API_CHAT_ENDPOINT;
export const gFitUrl = process.env.REACT_APP_API_GFIT_ENDPOINT;
export const azureRedirectUrl = process.env.REACT_APP_AZURE_REDIRECT_URL;
export const azureUserFlow = process.env.REACT_APP_AZURE_USER_FLOW;
export const azureAuthorityUrl = process.env.REACT_APP_AZURE_AUTHORITY_URL;
export const azureAuthorityDomainUrl =
  process.env.REACT_APP_AZURE_AUTHORITY_DOMAIN_URL;
export const azureClientId = process.env.REACT_APP_AZURE_CLIENTID;

export const getLocalStorageItem = (key) => localStorage.getItem(key);

export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, value);

export const removeLocalStorageItem = (key) => localStorage.removeItem(key);

export const isUpperCase = (value) => /[A-Z]/.test(value);

export const isLowerCase = (value) => /[a-z]/.test(value);

export const isContainNumber = (value) => /\d/.test(value);

export const isSpecialChar = (value) =>
  /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/.test(value);

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const loadingFalse = customEventFun(false);
export const loadingTrue = customEventFun(true);
export const isTokenExpiredFalse = tokenExpiredFun(false);
export const isTokenExpiredTrue = tokenExpiredFun(true);

export   const gridOptions = {
  getContextMenuItems: (params) => {
    const defaultItems = params.defaultItems.filter((item) => item !== 'export');
    
    return defaultItems;
  }
}
