import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo, useRef } from "react";
import ConversationView from "./ConversationView";
import {
  SetParticipantsType,
  SetSidType,
  SetUnreadMessagesType,
} from "../../types";
import { actionCreators, AppState } from "../../store";
import { getTypingMessage, unexpectedErrorNotification } from "../../helpers";
import {
  ERROR_UNABLE_TO_CONNECT,
  SUCCESS,
  UNEXPECTED_ERROR_MESSAGE,
} from "../../constants";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import {
  getSdkConversationObject,
  conversationsMap,
} from "../../conversations-objects";
import { ReduxMessage } from "../../store/reducers/messageListReducer";
import styles from "../../styles";
import { PiSearch } from "pixel-kit";
import { useParams } from "react-router-dom";
import { triggerApi } from "../../services/api-services";
import apiEndpoint from "../../core/apiend_point";
import { PayloadProps } from "../../core/schema";
import Snackbar from "../../ui-components/snackbar/snackbar";

interface ConvosContainerListProps {
  setShowLoader: (loader: boolean) => void;
}

interface ReservedUser {
  client_id: string;
  client_name: string;
  conversation_id: string;
  is_conversation_created: boolean
}

const ConversationsList: React.FC<ConvosContainerListProps> = (
  props: ConvosContainerListProps
) => {
  const [searchValue, setSearchValue]: any = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [filteredResults, setFilteredResults]: any = useState([]);
  const [chatInfo, userChatInfo] = useState<any>();
  const [reservedUsers, setReservedUsers] = useState<ReservedUser[]>([]);
  const [filteredReservedUsers, setFilteredReservedUsers]: any = useState([]);
  const [showSectionMsgErr, setShowSectionMsgErr] = useState(false);
  const [infoErrMsg, setInfoErrMsg] = useState("");
  const sid = useSelector((state: AppState) =>
    state.sid
      ? state.sid
      : chatInfo
      ? chatInfo["conversation_details"]
        ? chatInfo["conversation_details"]["conversation_id"]
        : ""
      : ""
  );
  const conversations = useSelector((state: AppState) => state.convos);
  const messages = useSelector((state: AppState) => state.messages);
  const unreadMessages = useSelector((state: AppState) => state.unreadMessages);
  const participants = useSelector((state: AppState) => state.participants);
  const typingData = useSelector((state: AppState) => state.typingData);
  const [notAvailable, setNotAvailable] = useState(false);
  const dispatch = useDispatch();
  const params: any = useParams();
  const [cid, setCid] = useState(params.id);
  const {
    updateCurrentConversation,
    updateParticipants,
    updateUnreadMessages,
    setLastReadIndex,
    addNotifications,
  } = bindActionCreators(actionCreators, dispatch);

  const [load, updateLoad] = useState(false);
  let convMap: any = [...conversationsMap.values()];
  const [conversation, setConvesation] = useState([]);
  useEffect(() => {
    async function updateCurrentConvo(
      setSid: SetSidType,
      convo: any,
      updateParticipants: SetParticipantsType
    ) {
      setSid(convo);
      try {
        const participants = await getSdkConversationObject(
          convo
        ).getParticipants();
        updateParticipants(participants, convo.sid);
      } catch {
        return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
      }
    }
    if (cid) {
    updateCurrentConvo(updateCurrentConversation, cid, updateParticipants);
    }
  },[cid])
  useEffect(() => {
    let chatInfo = localStorage.getItem("userChat");
    let chatData = chatInfo ? JSON.parse(chatInfo) : {};
    let conList: any = [];
    function conversationList(convMap: any) {
      convMap.map((el: any) => {
        return conList.push({
          sid: el.sid,
          friendlyName: el.friendlyName,
          dateUpdated: el.dateUpdated,
          lastReadMessageIndex: el.lastReadMessageIndex,
          notificationLevel: el.notificationLevel,
          lastMessage: {
            ...el.lastMessage,
          },
        });
      });
      setConvesation(conList);
    }
    conversationList(convMap);
    conList.sort(
      (a: any, b: any) =>
        (b.lastMessage?.dateCreated?.getTime() ??
          b.dateUpdated?.getTime() ??
          0) -
        (a.lastMessage?.dateCreated?.getTime() ?? a.dateUpdated?.getTime() ?? 0)
    );
    userChatInfo(chatData);
    setTimeout(() => {
      updateLoad(true);
    }, 1000);
    setFilteredResults(
      conversation.filter((item: any) => {
        return Object.values(item.friendlyName.split("(").shift())
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      })
    );
    setFilteredReservedUsers(
      reservedUsers.filter((item: ReservedUser) => {
        return item.client_name.toLowerCase().includes(searchValue.toLowerCase());
      })
    );
    if (filteredResults.length === 0 && filteredReservedUsers.length === 0) {
      setNotAvailable(true);
    } else {
      setNotAvailable(false);
    }
    props.setShowLoader(false);
  }, [searchValue, conversations, filteredResults.length, unreadMessages]);

  useEffect(() => {
    loadReservedParticipants();
    setFilteredReservedUsers(
      reservedUsers.filter((item: ReservedUser) => {
        return item.client_name.toLowerCase().includes(searchValue.toLowerCase());
      })
    );
  }, [searchValue]);
  
  function getLastMessage(messages: ReduxMessage[], typingData: string[]) {
    if (messages === undefined || messages === null) {
      return "Loading...";
    }
    if (typingData.length) {
      return getTypingMessage(typingData);
    }
    if (messages.length === 0) {
      return "No messages";
    }
    return messages[messages.length - 1].body || "Media message";
  }

  function isMyMessage(messages: ReduxMessage[]) {
    if (messages === undefined || messages === null || messages.length === 0) {
      return false;
    }
    return messages[messages.length - 1].author ===
      localStorage.getItem("username")
      ? messages[messages.length - 1]
      : false;
  }

  async function updateCurrentConvo(
    setSid: SetSidType,
    convo: ReduxConversation,
    updateParticipants: SetParticipantsType
  ) {
    setSid(convo.sid);
    try {
      const participants = await getSdkConversationObject(
        convo
      ).getParticipants();
      updateParticipants(participants, convo.sid);
    } catch {
      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  }

  function setUnreadMessagesCount(
    currentconvoSid: string,
    convoSid: string,
    unreadMessages: Record<string, number>,
    updateUnreadMessages: SetUnreadMessagesType
  ) {
    if (currentconvoSid == convoSid && unreadMessages[convoSid] !== 0) {
      updateUnreadMessages(convoSid, 0);
      return 0;
    }
    if (currentconvoSid == convoSid) {
      return 0;
    }
    return unreadMessages[convoSid];
  }

  const loadReservedParticipants = async () => {
    const apiObject: PayloadProps = {
      payload: {},
      method: "GET",
      headers: { "Content-Type": "application/json" },
      apiUrl: `${apiEndpoint.participantsList}`,
    };
    await triggerApi(apiObject)
      .then((response: any) => {
        if (response.status_code === 200 && response.status === SUCCESS) {
          response.data.length ? setReservedUsers(response.data) : null;
        } else {
          setInfoErrMsg(response?.message || ERROR_UNABLE_TO_CONNECT);
          setShowSectionMsgErr(true);
          setTimeout(() => {
            setShowSectionMsgErr(false);
            setInfoErrMsg("");
          }, 1500);
        }
      })
      .catch((err: object) => {
        console.log(err);
      });
  };

  const createConversation = async (clientId: string) => {
    const apiObject: PayloadProps = {
      payload: { user_id: clientId },
      method: "POST",
      headers: { "Content-Type": "application/json" },
      apiUrl: `${apiEndpoint.createConversation}`,
    };
    triggerApi(apiObject)
      .then(async (response: any) => {
        if (response.status_code === 200 && response.status === SUCCESS) {
          setCid(response.data.conversation_id);
        } else {
          setInfoErrMsg(response?.message || ERROR_UNABLE_TO_CONNECT);
          setShowSectionMsgErr(true);
          props.setShowLoader(false);
          setTimeout(() => {
            setShowSectionMsgErr(false);
            setInfoErrMsg("");
          }, 1500);
        }
      })
      .catch((err: object) => {
        console.log(err);
      });
  };

  // const sid = useSelector((state: AppState) => state.sid);
  const searchClients = (e: any) => {
    setSearchValue(e.target.value);
  };
  if (conversations === undefined || conversations === null) {
    return <div className="empty" />;
  }

  return (
    <div id="conversation-list" style={styles.convoList}>
      <Snackbar
        title="Error"
        appearance="error"
        message={infoErrMsg}
        open={showSectionMsgErr}
        close={() => setShowSectionMsgErr(false)}
      />
      <div className="chat-search-box">
        <PiSearch
          libraryType="atalskit"
          onClear={function clearSearch() {
            setSearchValue("");
          }}
          onKeyUp={function noRefCheck() {}}
          value={searchValue}
          placeholder={"Search"}
          onValueChange={searchClients}
        />
      </div>
      <div className="conv-list-wrapper">
        {searchValue.length > 0 ? (
          <>
            {filteredResults.map((convo: any) => (
              <ConversationView
                key={convo.sid}
                convoId={convo.sid}
                setSid={updateCurrentConversation}
                currentConvoSid={sid}
                lastMessage={
                  getLastMessage(
                    messages[convo.sid],
                    typingData[convo.sid] ?? []
                  ) ?? ""
                }
                messages={messages[convo.sid]}
                typingInfo={typingData[convo.sid] ?? []}
                myMessage={isMyMessage(messages[convo.sid])}
                unreadMessagesCount={setUnreadMessagesCount(
                  sid,
                  convo.sid,
                  unreadMessages,
                  updateUnreadMessages
                )}
                updateUnreadMessages={updateUnreadMessages}
                participants={participants[convo.sid] ?? []}
                convo={convo}
                onClick={async () => {
                  try {
                    localStorage.setItem(
                      "unreadMessageCount",
                      JSON.stringify(
                        setUnreadMessagesCount(
                          sid,
                          convo.sid,
                          unreadMessages,
                          updateUnreadMessages
                        )
                      )
                    );
                    setLastReadIndex(convo.lastReadMessageIndex ?? -1);
                    await updateCurrentConvo(
                      updateCurrentConversation,
                      convo,
                      updateParticipants
                    );
                    //update unread messages
                    updateUnreadMessages(convo.sid, 0);
                    //set messages to be read
                    const lastMessage =
                      messages[convo.sid].length &&
                      messages[convo.sid][messages[convo.sid].length - 1];
                    if (lastMessage && lastMessage.index !== -1) {
                      await getSdkConversationObject(
                        convo
                      ).updateLastReadMessageIndex(lastMessage.index);
                    }
                  } catch {
                    unexpectedErrorNotification(addNotifications);
                  }
                }}
              />
            ))}
            {filteredReservedUsers.length &&
              filteredReservedUsers.map((convo: any) => (
                <ConversationView
                  key={convo.client_id}
                  convoId={""}
                  setSid={updateCurrentConversation}
                  currentConvoSid={sid}
                  lastMessage={""}
                  messages={[]}
                  typingInfo={[]}
                  myMessage={false}
                  unreadMessagesCount={0}
                  updateUnreadMessages={updateUnreadMessages}
                  participants={[]}
                  convo={{
                    sid: "",
                    friendlyName: convo.client_name,
                    dateUpdated: null,
                    notificationLevel: "default",
                    lastReadMessageIndex: null,
                  }}
                  onClick={async () => {
                    props.setShowLoader(true);
                    createConversation(convo.client_id);
                  }}
                />
              ))}
            {notAvailable && (
              <p
                style={{
                  fontSize: "16PX",
                  color: "#45454a",
                  fontWeight: "600",
                  margin: "auto",
                }}
              >
                No Results Found
              </p>
            )}
          </>
        ) : (
          <>
            {conversation.map((convo: any) => (
              <ConversationView
                key={convo.sid}
                convoId={convo.sid}
                setSid={updateCurrentConversation}
                currentConvoSid={sid}
                lastMessage={
                  getLastMessage(
                    messages[convo.sid],
                    typingData[convo.sid] ?? []
                  ) ?? ""
                }
                messages={messages[convo.sid]}
                typingInfo={typingData[convo.sid] ?? []}
                myMessage={isMyMessage(messages[convo.sid])}
                unreadMessagesCount={setUnreadMessagesCount(
                  sid,
                  convo.sid,
                  unreadMessages,
                  updateUnreadMessages
                )}
                updateUnreadMessages={updateUnreadMessages}
                participants={participants[convo.sid] ?? []}
                convo={convo}
                onClick={async () => {
                  try {
                    localStorage.setItem(
                      "unreadMessageCount",
                      JSON.stringify(
                        setUnreadMessagesCount(
                          sid,
                          convo.sid,
                          unreadMessages,
                          updateUnreadMessages
                        )
                      )
                    );
                    setLastReadIndex(convo.lastReadMessageIndex ?? -1);
                    await updateCurrentConvo(
                      updateCurrentConversation,
                      convo,
                      updateParticipants
                    );
                    //update unread messages
                    updateUnreadMessages(convo.sid, 0);
                    //set messages to be read
                    const lastMessage =
                      messages[convo.sid].length &&
                      messages[convo.sid][messages[convo.sid].length - 1];
                    if (lastMessage && lastMessage.index !== -1) {
                      await getSdkConversationObject(
                        convo
                      ).updateLastReadMessageIndex(lastMessage.index);
                    }
                  } catch {
                    unexpectedErrorNotification(addNotifications);
                  }
                }}
              />
            ))}
            {reservedUsers.length &&
              reservedUsers.map((convo: ReservedUser) => (
                <ConversationView
                  key={convo.client_id}
                  convoId={""}
                  setSid={updateCurrentConversation}
                  currentConvoSid={sid}
                  lastMessage={""}
                  messages={[]}
                  typingInfo={[]}
                  myMessage={false}
                  unreadMessagesCount={0}
                  updateUnreadMessages={updateUnreadMessages}
                  participants={[]}
                  convo={{
                    sid: "",
                    friendlyName: convo.client_name,
                    dateUpdated: null,
                    notificationLevel: "default",
                    lastReadMessageIndex: null,
                  }}
                  onClick={async () => {
                    props.setShowLoader(true);
                    createConversation(convo.client_id);
                  }}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ConversationsList;
