import React from "react";
import Loader from "../../assets/images/loader.gif";
import { Spinn } from "./Spinener.component";

export default function Spinner() {
  return (
    <Spinn>
      <img src={Loader} />
    </Spinn>
  );
}
