import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./LoaderComponent.css";
export const GridLoader = () => {
  return (
    <div>
      <div style={{ paddingBottom: "20px" }}>
        <Skeleton width="100%" />
      </div>
      <LoaderComponent />
      <LoaderComponent />
      <LoaderComponent />
      <LoaderComponent />
      <LoaderComponent />
    </div>
  );
};

export const LoaderComponent = () => {
  return (
    <div className="loader-component">
      <Skeleton width={120} height={30} />
      <Skeleton width={120} height={30} />
      <Skeleton width={120} height={30} />
      <Skeleton width={120} height={30} />
      <Skeleton width={120} height={30} />
    </div>
  );
};
