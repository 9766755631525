import { useState, Fragment } from "react";
import { Formik } from "formik";
import CrossLogo from "../../assets/cross.svg";
import {
  PiTypography,
  PiButton,
  PiModal,
  PiModalBody,
  PiTextareaForm,
  PiSpinner,
} from "pixel-kit";
import { FilterFormFields } from "./ReschedulePopupComponent";
import { dismissInitialValues } from "./helpers/timeSlot";
import { DismissSlotSchema } from "./validation/dismissValidation";
import {
  PopupHeaderContentDiv,
  PopupHeaderDiv,
  SpinnerDiv,
  CloseButton,
  AddFooter,
} from "./ReschedulePopupComponent";
import apiEndpoint from "../../core/apiend_point";
import { triggerApi } from "../../services/api-services";
import { PayloadProps } from "../../core/schema";
import { ERROR_WHILE_FETCHING_MESSAGE, SUCCESS } from "../../constants";

export default function DismissPopup(props: any) {
  const [isloading, setIsloading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const dismiss = props.dismiss;

  function handleSubmit(data: any) {
    setIsloading(true);
    setIsDisabled(true);
  
    let apiObject: PayloadProps;

    if (props.taskId) {
      apiObject = {
        payload: {
          comment: data.description,
        },
        method: "DELETE",
        apiUrl: `${apiEndpoint.toBeScheduled}/${props.taskId}`,
      };
    } else if (props?.dismissData && Object.keys(props?.dismissData).length > 0) {
      apiObject = {
        payload: { ...props.dismissData ,
          comments: data.description
        },
        method: "POST",
        apiUrl: `${apiEndpoint.comments}`,
      };
    } else {
      apiObject = {
        payload: {
          status_id: props.dismissId,
          comment: data.description,
        },
        method: "PUT",
        apiUrl: `${apiEndpoint.updateCallRequest}/${props.reqId}`,
      };
    }
    triggerApi(apiObject)
      .then((response) => {
        if (response.status_code === 200 && response.status === SUCCESS) {
          props.setsnackBarMsgs(response.message);
          props.setShowSectionMsgs(true);
          props.loadClientRequests();
          props.onCancel();
          setTimeout(() => {
            props.setShowSectionMsgs(false);
            props.setsnackBarMsgs("");
          }, 2000);
        } else {
          props.setShowSectionMsgs(true);
          props.setsnackBarMsgs(
            response?.message || ERROR_WHILE_FETCHING_MESSAGE
          );
          setTimeout(() => {
            props.setShowSectionMsgs(false);
            setIsloading(false);
            setIsDisabled(false);
            props.onCancel();
          }, 1500);
        }
      })
      .catch((err: object) => {
        console.log(err);
      });
  }

  function handleRef(e: any) {
    // formik.current = e;
  }

  return (
    <Fragment>
      <PiModal isOpen={dismiss}>
        <PopupHeaderContentDiv>
          <PopupHeaderDiv>
            <PiTypography component="h5">Confirm Dismiss</PiTypography> 
            {
              <CloseButton
                onClick={() => props.onCancel(false)}
                title="close"
                className="Hover"
              >
                <img src={CrossLogo} alt="loading"></img>
              </CloseButton>
            }
          </PopupHeaderDiv>
        </PopupHeaderContentDiv>

        <div className="dismiss-form-wrap">
          <Formik
            onSubmit={handleSubmit}
            initialValues={dismissInitialValues}
            initialFocus={false}
            innerRef={handleRef}
            validationSchema={DismissSlotSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ ...formik }: any) => {
              return (
                <>
                  <PiModalBody>
                    <FilterForm />
                  </PiModalBody>
                  <div className="popup-footer">
                    <AddFooter>
                      <PiButton
                        appearance="secondary"
                        label="Cancel"
                        onClick={() => props.onCancel(false)}
                      />
                      <PiButton
                        appearance="primary"
                        label="Confirm"
                        onClick={formik.handleSubmit}
                        isLoading={isloading ? true : false}
                        isDisabled={(isDisabled)}
                      />
                    </AddFooter>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      </PiModal>
    </Fragment>
  );
}

const FilterForm = () => {

  return (
    <FilterFormFields>
      <>
        <div className="Feilds">
          <PiTextareaForm
            name="description"
            label="Reason"
            libraryType="atalskit"
            autoFocus={false}
            placeholder="Description"
            onChange={(e: any) => {
            }}
          />
        </div>
      </>
    </FilterFormFields>
  );
};
