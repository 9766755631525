import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Client } from "@twilio/conversations";

import Logo from "../../assets/logo.svg";
import ChatIcon from "../../assets/chat2.svg";
import DashboardIcon from "../../assets/dashboard2.svg";
import ClientsIcon from "../../assets/clients2.svg";
import DietIcon from "../../assets/Diet.svg";
import { setLocalStorageItem } from "../../core/localStorageService";
import {
  Label,
  LabelCOntent,
} from "../../modules/dashboard/DashboardComponent";
import { triggerApi } from "../../services/api-services";
import { PayloadProps } from "../../schema/schema";
import { actionCreators } from "../../store";
import { handlePromiseRejection } from "../../helpers";
import { loadUnreadMessagesCount } from "../../components/AppContainer";

import "./sidebar.css";

function Sidebar() {
  const dispatch = useDispatch();

  const { login, updateUnreadMessages, addNotifications } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const unreadMessages = useSelector((state: any) => state.unreadMessages);

  useEffect(() => {
    getChatToken();
  }, []);

  const getChatToken = () => {
    const apiObject: PayloadProps = {
      payload: {},
      method: "GET",
      apiUrl: process.env.REACT_APP_CHAT_API,
      nodeUrl: process.env.REACT_APP_CHAT_API,
    };
    triggerApi(apiObject).then((response) => {
      if (response.status_code === 200 && response.status === "success") {
        const iframeUrl =
          response.app_url +
          "?token=" +
          response.token +
          "&name=" +
          response.identity;
        login(response.token);
        const client = new Client(response.token);
        client.on("conversationJoined", (conversation) => {
          handlePromiseRejection(() => {
            if (conversation.status === "joined") {
              loadUnreadMessagesCount(conversation, updateUnreadMessages);
            }
          }, addNotifications);
        });
        client.on("messageAdded", (messages) => {
          handlePromiseRejection(() => {
            loadUnreadMessagesCount(
              messages.conversation,
              updateUnreadMessages
            );
          }, addNotifications);
        });
        setLocalStorageItem("chatInfo", JSON.stringify(response));
        localStorage.setItem("iframeUrl", iframeUrl);
      }
    });
  };

  return (
    <div className="sidebar">
      <ul>
        <li>
          <span>
            <img className="logo" src={Logo} alt="logoicon" />
          </span>
        </li>
        <div className="content">
          <li>
            <NavLink className="link" to="/">
              {" "}
              <img src={DashboardIcon} alt="dahboardicon" /> Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="/chat">
              <img src={ChatIcon} alt="chaticon" /> Chat
              {unreadMessages.unreadCounts > 0 && (
                <Label>
                  {" "}
                  <LabelCOntent>
                    {unreadMessages.unreadCounts < 99
                      ? unreadMessages.unreadCounts
                      : "99+"}
                  </LabelCOntent>
                </Label>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="/clients">
              <img src={ClientsIcon} alt="clientsicon" /> Clients
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="/diet">
              <img src={DietIcon} alt="DietIcon" /> Diet Template
            </NavLink>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default Sidebar;
