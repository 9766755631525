import React from 'react'
import Oops from '../assets/Ops.svg'

function Unauthorized() {
  return (
    <div className='unauth'>
      <img src={Oops} alt="oops" />
      <h2>Oh no!</h2>
      <p>You are not authorized to access this application</p>  
    </div> 
  )
}

export default Unauthorized
