import { PiButton, PiModal, PiModalBody } from "pixel-kit";
import SessionExpIcon from "../assets/images/session-exp-icon.png";
// import { useRef } from "react";
import { useMsal } from "@azure/msal-react";
// import { useDetectOutsideClick } from "./UseDetectOutsideClick";
import "./SignOutButton.css";
const TokenExpiredModal = ({ isModalOpen, handleModalClose }: any) => {
  const { instance } = useMsal();
  // const dropdownRef = useRef(null);
  // const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  const handleLogout = (logoutType: string) => {
    // setAnchorEl(null);
    localStorage.clear();
    if (logoutType === "popup") {
      instance.logoutPopup({
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  };
  return (
    <PiModal onClose={function noRefCheck() {
      //
    }} isOpen={isModalOpen}>
      <PiModalBody>
        <div className="popup-content">
          <img src={SessionExpIcon} alt="session expired" />
          <h4>Session Expired</h4>
          <span>
            Oh No! Your session timed out after a long time of inactivity,
          </span>
          <span>Please click on the "LOGIN".</span>
          <div className="button">
            <PiButton
              appearance="primary"
              onClick={() => handleLogout("redirect")}
              label="LOGIN"
            />
          </div>
        </div>
      </PiModalBody>
    </PiModal>
  );
};
export default TokenExpiredModal;
