import {
  LoginContentWrapper,
  LoginTitle,
  LoginWrapper,
} from "./sign-in-components";
import Logo from "../../assets/logo.svg";
import { SignInSignOutButtonHome } from "../../ui-components/SignInSignOutButton";

function SignIn() {
  return (
    <LoginWrapper>
      <LoginContentWrapper>
        <img src={Logo} alt="logo"></img>
        <LoginTitle>Health Coach</LoginTitle>
        <SignInSignOutButtonHome />
      </LoginContentWrapper>
    </LoginWrapper>
  );
}

export default SignIn;
