import React, { useState, Fragment, useEffect } from "react";
import { Formik } from "formik";
import CrossLogo from "../../../assets/cross.svg";
import { useParams } from "react-router-dom";
import {
  PiTypography,
  PiButton,
  PiModal,
  PiModalHeader,
  PiSpinner,
  PiSelectForm,
  PiDatePicker,
  PiTextareaForm,
} from "pixel-kit";
// import { ApiResponse } from 'src/services/schema/schema'
import {
  AddFooter,
  FieldsContainer,
  FilterFormFields,
  FormField,
} from "../../../modules/dashboard/ReschedulePopupComponent";
import {
  PopupHeaderContentDiv,
  PopupHeaderDiv,
  SpinnerDiv,
  CloseButton,
} from "../../../modules/dashboard/ReschedulePopupComponent";
import { triggerApi } from "../../../services/api-services";
import { PayloadProps } from "../../../core/schema";
import apiEndpoint from "../../../core/apiend_point";
import { ScheduleConsultSchema } from "../validation/ScheduleConsultValidation";
import { initialValues } from "../helpers/ScheduleConsult";
import "../../../modules/dashboard/Reschedule.css";
import Snackbar from "../../../ui-components/snackbar/snackbar";

export default function ScheduleConsulatation(props: any) {
  const [timeslot, setTimeslot] = useState([]);
  const [todayDate, SetTodayDate]: any = useState("");
  const [maxDate, setMaxDate]: any = useState("");
  const [isloading, setIsloading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setloading] = useState(true);
  const [currentTime, setCurrentTime]: any = useState("");
  const [selectDate, setSelectDate]: any = useState();
  const isChecked = true;
  const [isSubmit, setIsSubmit] = useState(false);
  const params: any = useParams();
  const [showSectionMsg, setShowSectionMsg] = useState(false);
  const [snackBarMsg, setsnackBarMsg] = useState("");
  const [showSectionMsgErr, setShowSectionMsgErr] = useState(false);
  const [infoErrMsg, setInfoErrMsg] = useState("");
  useEffect(() => {
    const formatDate = (date: Date) => {
      const d = new Date(date);
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    };

    SetTodayDate(formatDate(new Date()));

    const date = new Date();
    const maxDays = Number(
      process.env.REACT_APP_HEALTHCOACH_SCHEDULE_CONSULTATION_MAX_DATE
    );
    date.setDate(date.getDate() + maxDays);
    setMaxDate(formatDate(date));

    const slotDate = new Date();
    const current_time = slotDate.getHours() + 1;
    setCurrentTime(current_time);

    const apiObject: PayloadProps = {
      payload: {},
      method: "GET",
      headers: { "Content-Type": "application/json" },
      apiUrl: `${apiEndpoint.timeSlots}`,
    };

    triggerApi(apiObject).then((response: any) => {
      if (response.status_code === 200 && response.status === "success") {
        setTimeslot(response?.data);
      } else {
        setTimeslot([]);
      }
    });

    setTimeout(() => {
      setloading(false);
    }, 20);
  }, []);

  function handleRef(e: any) {}

  function handleSubmit(data: any) {
    if (isSubmit) return;
    data.date = selectDate;
    setIsloading(true);
    setIsDisabled(true);
    let obj: any;
    let url: string = "";
    if (props?.taskId) {
      obj = {
        reason_name: data.reason ? data.reason : "",
        consultation_date: data.date ? data.date : "",
        timeslot_id: data && data.time && data.time._id ? data.time._id : "",
        task_id: props.taskId,
      };
      url = apiEndpoint.toBeScheduled;
    } else {
      obj = {
        reason_name: data.reason ? data.reason : "",
        consultation_date: data.date ? data.date : "",
        timeslot_id: data && data.time && data.time._id ? data.time._id : "",
        user_id: params.id,
      };
      url = apiEndpoint.callRequests;
    }
    const apiObject: PayloadProps = {
      payload: obj,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      apiUrl: url,
    };
    triggerApi(apiObject).then((response) => {
      if (response.status === "success") {
        setsnackBarMsg(response.message);
        setShowSectionMsg(true);
        setTimeout(() => {
          setShowSectionMsg(false);
          setsnackBarMsg(" ");
          props.onCancel(false);
          if (props.taskId) {
            props.loadToBeScheduledCalls();
          } else {
            props.handleTab();
          }
        }, 3000);
      } else {
        setInfoErrMsg(
          response?.response?.data?.message ||
            response?.message ||
            "Error while fetching data ..."
        );
        setShowSectionMsgErr(true);
        props.onCancel(false);
        setTimeout(() => {
          setIsloading(false);
          setIsDisabled(false);
          setShowSectionMsgErr(false);
          setInfoErrMsg("");
        }, 1500);
      }
    });
  }

  return (
    <Fragment>
      <Snackbar
        title="Success"
        appearance="success"
        message={snackBarMsg}
        open={showSectionMsg}
        close={() => setShowSectionMsg(false)}
      />
      <Snackbar
        title="Error"
        appearance="error"
        message={infoErrMsg}
        open={showSectionMsgErr}
        close={() => setShowSectionMsgErr(false)}
      />
      <div>
        <PiModal isOpen={props.showConsult}>
          <PopupHeaderContentDiv>
            <PopupHeaderDiv>
              <PiTypography component="h5">Schedule Consultation</PiTypography>
              {
                <CloseButton
                  onClick={() => props.onCancel(false)}
                  title="close"
                  className="Hover"
                >
                  <img src={CrossLogo} alt="loading"></img>
                </CloseButton>
              }
            </PopupHeaderDiv>
          </PopupHeaderContentDiv>

          {!loading && (
            <div className="schedle-form-wrap">
              <Formik
                onSubmit={handleSubmit}
                validationSchema={ScheduleConsultSchema}
                initialValues={initialValues}
                innerRef={handleRef}
              >
                {({ ...formik }: any) => {
                  return (
                    <>
                      <FilterForm
                        fieldValue={formik.setFieldValue}
                        isChecked={isChecked}
                        timeslot={timeslot}
                        todayDate={todayDate}
                        maxDate={maxDate}
                        currentTime={currentTime}
                        setDate={setSelectDate}
                        isSubmit={isSubmit}
                        setIsSubmit={setIsSubmit}
                      />
                      <div className="popup-footer">
                        <AddFooter>
                          <PiButton
                            appearance="secondary"
                            label="Cancel"
                            onClick={() => props.onCancel(false)}
                          />
                          <PiButton
                            appearance="primary"
                            label="Save"
                            onClick={formik.handleSubmit}
                            isLoading={isloading ? true : false}
                            isDisabled={isDisabled ? true : false}
                          />
                        </AddFooter>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          )}
        </PiModal>
      </div>
    </Fragment>
  );
}

const FilterForm = ({
  timeslot,
  todayDate,
  maxDate,
  currentTime,
  setDate,
  isSubmit,
  setIsSubmit,
  fieldValue,
}: any) => {
  function getInput(e: any) {
    if (e.target.value.length > 30) {
      setIsSubmit(true);
    }
    if (e.target.value.length < 30) {
      setIsSubmit(false);
    }
  }

  const [slots, setSlots] = useState<any>([]);

  const checkTimeSlots = (e: any) => {
    fieldValue("time", "");
    setDate(e);
    if (timeslot && timeslot.length) {
      const slotsArray = timeslot.map((slot: any) => {
        if (
          slot.value < currentTime &&
          new Date(e).getTime() <= new Date().getTime()
        ) {
          return { ...slot, isDisabled: true };
        }
        return slot;
      });
      setSlots(slotsArray);
    }
  };
  return (
    <FilterFormFields>
      <>
        <FieldsContainer>
          <div className="Feilds">
            <PiDatePicker
              dateFormat="YYYY-MM-DD"
              label="Select Date"
              name={"date"}
              onChange={(e: any) => checkTimeSlots(e)}
              minDate={todayDate}
              maxDate={maxDate}
              placeholder={"YYYY-MM-DD"}
            />
          </div>
          <div className="Feilds">
            <PiSelectForm
              label={"Select Time Slot"}
              name={"time"}
              onChange={function noRefCheck() {}}
              options={slots}
              placeholder={"select time slot"}
            />
          </div>
          <div className="Feilds">
            <PiTextareaForm
              name="reason"
              label="Reason"
              libraryType="atalskit"
              placeholder="Reason"
              onChange={(e: any) => {
                getInput(e);
              }}
            />
            {isSubmit && (
              <span className="formerrors">
                description must be at most 30 characters
              </span>
            )}
          </div>
        </FieldsContainer>
      </>
    </FilterFormFields>
  );
};
