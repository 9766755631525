import styled from "styled-components";

export const Label= styled.div`
width:22px;
display:inline-block; 
height:22px;
border-radius:50%;
background-color:red;
text-align:center; 
margin-left:8px; 
` 
export const LabelCOntent= styled.span`
color:white;
font-weight:600; 
font-size:14px; 
line-height:22px;  
`   