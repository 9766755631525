import Validations from "../../../core/validations/validations";

export const messages = {
  description: {
    required: "Reason is required",
    max: "Reason must be at most 250 characters"
  },
};

export const DismissSlotSchema = Validations(
  {
    description: "required|max:250",
  },
  messages
);
