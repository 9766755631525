import { useHistory } from "react-router-dom";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { CellClassParams } from "ag-grid-community";
import { useRef, useState } from "react";
import { gridOptions } from "../../utils/helper";
import apiEndpoint from "../../core/apiend_point";
import { triggerApi } from "../../services/api-services";
import { PayloadProps } from "../../core/schema"
import { dateFormatter } from "../../services/helper";
import { NO_RECORD_FOUND } from "../../constants";
import "./FlareUp.css";
import FlareUpModal from "../../components/flare-up-modal/modal";

const FlareUp = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false)
  const [flareUpReportData, setFlareUpReportData] = useState<any>();
  const [overlayNoRowsMessage, setOverlayNoRowsMessage] =
    useState("No data found");
  const gridRef = useRef(null);
  const defaultColDef = {
    wrapText: true,
    sortable: true,
    editable: false,
    flex: 1,
    suppressMenu: true,
    autoHeight: true,
    resizable: true,
    floatingFilter: true,
  };
  const cellStyle = (params: CellClassParams) => {
    const cursor = "pointer";
    const color = "blue";
    const textDecoration = "underline";
    return {
      cursor: cursor,
      textDecoration: textDecoration,
      color: color,
    };
  };

  const loadFlareUpData = {
    getRows(params: any) {
      const { startRow, filterModel, sortModel } = params.request;
      const modifiedStartRow = startRow;
      const modifiedEndRow = 49;
      let apiUrl = `${apiEndpoint.flareUpV2}?`;
      params.api.hideOverlay();
      Object.entries(filterModel).forEach(([filter, value]) => {
        if (filter === "when" || filter === "created_date") {
          if (value && (value as { dateFrom: Date }).dateFrom) {
            const date = new Date((value as { dateFrom: Date }).dateFrom);
            const filterValue = dateFormatter(date);
            apiUrl += `${filter}=${filterValue}&`;
            setOverlayNoRowsMessage(NO_RECORD_FOUND)
          }
        } else {
          apiUrl += `${filter}=${(value as { filter: string }).filter}&`;
          setOverlayNoRowsMessage(NO_RECORD_FOUND)
        }
      });
         // sorting
      if (sortModel.length) {
        const { colId, sort } = sortModel[0];
        apiUrl += `order_by=${colId}&order=${sort}&`;
      }
      //pagination
      apiUrl += `limit=${modifiedEndRow}&offset=${modifiedStartRow}`;

      const apiObject : PayloadProps = {
        payload: {},
        method: "GET",
        headers: { "Content-Type": "application/json" },
        apiUrl: apiUrl,
      };

      triggerApi(apiObject).then((res)=>{
       if(res?.status === "success"){
        if(res?.data?.length === 0){
          params.api.hideOverlay();
          params.api.showNoRowsOverlay();
          params.successCallback(res?.data, res?.count);
        }
        else{
          params.successCallback(res?.data, res?.count);
        }
       }
      })
    },
  };

  const onGridReady = (params: any) => {
    params.api.setServerSideDatasource(loadFlareUpData);
  };

  const viewMoreData = (params : any) => {
    return(
       <button onClick={()=>{
        setFlareUpReportData(params?.data)
        setShowModal(true)
       }} style={{border:"none", background:"transparent", color:"blue", cursor:"pointer"}}>
        <p style={{fontSize:"12px", fontWeight:600}}>View More</p>
       </button>
    )
  }

  return (
    <div  className="callrequest">
    <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%" }}>
      <AgGridReact
        columnDefs={[
          {
            headerName: "Patient Name",
            field: "client_name",
            cellStyle: cellStyle,
            minWidth: 200,
            filter: "agTextColumnFilter",
            cellRenderer: (params: any) => {
              return params?.data?.client?.display_name
            },
            onCellClicked: function (params: any) {
              history.push({
                pathname: `/clients/${params?.data?.client?._id}`,
                search: `?i=7`
              })
            }
          },
          {
            headerName: "What flared up",
            field: "what",
            minWidth: 200,
            filter: "agTextColumnFilter",
          },
          {
            headerName: "Reported Date",
            field: "created_date",
            filter: "agDateColumnFilter",
            minWidth: 200,
            cellRenderer: (params: any) => {
              return moment(params?.data?.created_date).format("DD-MM-YYYY")
            },
          },
          {
            headerName: "Reported by",
            field: "created_by",
            minWidth: 200,
            filter: "agTextColumnFilter",
            cellRenderer: (params: any) => {
              return params?.data?.creator?.display_name
            },
          },
          {
            headerName: "Symptoms",
            field: "what",
            minWidth: 200,
            filter: "agTextColumnFilter",
            cellRenderer: (params: any) => {
              return params?.data?.symptoms?.join(", ")
            }
          },
          {
            headerName: "When it happened",
            field: "when",
            minWidth: 200,
            filter: "agDateColumnFilter",
          },
          {
            headerName: "Severity",
            field: "severity",
            minWidth: 100,
            filter: "agTextColumnFilter",
          },
          {
            headerName: "Diet Consumed",
            field: "diet_consumed",
            minWidth: 200,
            filter: "agTextColumnFilter",
          },
            {
            headerName: "",
            field: "_id",
            minWidth: 100,
            cellRendererFramework: viewMoreData
          },
        ]}
        ref={gridRef}
        pagination={true}
        paginationPageSize={7}
        defaultColDef={defaultColDef}
        rowModelType="serverSide"
        onGridReady={onGridReady}
        serverSideStoreType="partial"
        cacheBlockSize={49}
        animateRows={true}
        overlayNoRowsTemplate={overlayNoRowsMessage}
        gridOptions={gridOptions}
      />
    </div>
    <div>
      <FlareUpModal showModal={showModal} setShowModal={setShowModal} flareUpReportData={flareUpReportData} />
    </div>
    </div>
  );
};

export default FlareUp;
