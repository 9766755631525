import { Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

import Settings from "../settings/Settings";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import styles from "../../styles";
import PhoneIcon from "../../assets/icons/phone.svg";
import { useEffect, useState } from "react";
import { getLocalStorageItem } from "../../utils/helper";
import apiEndpoint from "../../core/apiend_point";
import { triggerApi } from "../../services/api-services";
import Snackbar from "../../ui-components/snackbar/snackbar";
import { PayloadProps } from "../../core/schema";
import { ERROR_UNABLE_TO_CONNECT } from "../../constants";

interface ConversationDetailsProps {
  convoSid: string;
  participants: ReduxParticipant[];
  convo: ReduxConversation;
}

//

const ConversationDetails: React.FC<ConversationDetailsProps> = (
  props: ConversationDetailsProps
) => {
  const [userId, setUserId] = useState<any>("");
  const [canInitiate, setCanInitiate] = useState<boolean>(false);
  const [showSectionMsgErr, setShowSectionMsgErr] = useState<boolean>(false);
  const [infoErrMsg, setInfoErrMsg] = useState<string>("");
  const theme = useTheme();

  const firstLetter = (props.convo.friendlyName ?? props.convo.sid).substring(
    0,
    1
  );
  const convoId = localStorage.getItem("sid");
  useEffect(() => {
    const token = getLocalStorageItem("accessToken");
    getPatientMobileNumber(token, convoId);
  }, [convoId]);

  const getPatientMobileNumber = (token: any, convoId: any) => {
    const apiObject = {
      payload: {},
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      apiUrl: `${apiEndpoint.clientDetails}/${convoId}`,
    };
    triggerApi(apiObject).then((res: any) => {
      setUserId(res.user_id);
    });
  };

  const  handleCall = () => {
    setCanInitiate(true);
    const apiObject: PayloadProps = {
      payload: {user_id : userId},
      method: "POST",
      headers: { "Content-Type": "application/json" },
      apiUrl: `${apiEndpoint.dialer}`, 
    };
    triggerApi(apiObject)
      .then((response:any) => {
        if (response.status_code === 200 && response.status === "success") {
          setTimeout(() => {
            setCanInitiate(false);
          }, 8000);
        } else {
          setInfoErrMsg(
            response?.message || ERROR_UNABLE_TO_CONNECT
          );
          setCanInitiate(false);
          setShowSectionMsgErr(true);
          setTimeout(() => { 
            setShowSectionMsgErr(false);
            setInfoErrMsg("");
          }, 1500); 
        }
      })
      .catch((err: object) => {
        console.log(err);
      });
  }

  return (
    <Box
      style={{
        minHeight: 65,
        maxHeight: 65,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.borderColors.colorBorderWeak,
      }}
    >
      <Snackbar
        title="Error"
        appearance="error"
        message={infoErrMsg}
        open={showSectionMsgErr}
        close={() => setShowSectionMsgErr(false)}
      />
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {canInitiate && (
          <div className="callAlert chatCallPosition">
            <span className="callSpan">Call Initiated</span>
          </div>
        )}
        <div style={styles.chatUserInfo}>
          <div style={styles.chatUserImage}>{firstLetter}</div>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            color="colorText"
            fontFamily="fontFamilyText"
            fontSize="fontSize50"
            lineHeight="lineHeight80"
            fontWeight="fontWeightBold"
            maxHeight="100%"
          >
            {props.convo.friendlyName ?? props.convo.sid}
          </Box>
        </div>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          color="colorText"
          fontFamily="fontFamilyText"
          fontSize="fontSize50"
          lineHeight="lineHeight80"
          fontWeight="fontWeightBold"
          maxHeight="100%"
        >
          <div style={{ display: "flex" }}>
            <button
              className={
                canInitiate ? "mobile disableButton" : "mobile client-button"
              }
              disabled={canInitiate}
              onClick={() => (!canInitiate ? handleCall() : null)}
            >
              <img
                className={canInitiate ? "svgColorBlack" : "svgColorWhite"}
                src={PhoneIcon}
                alt="not found"
              ></img>
              <span style={{ verticalAlign: "middle" }}> Click to Call</span>
            </button>
          </div>

          {/* <Box
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
            color="colorTextWeak"
            fontFamily="fontFamilyText"
            fontSize="fontSize30"
            lineHeight="lineHeight40"
            fontWeight="fontWeightNormal"
            paddingRight="space60"
          >
            {`${props.participants.length}`}
            {props.participants.length > 1 ? " participants" : " participant"}
          </Box> */}
          {/* <Settings convo={props.convo} participants={props.participants} /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationDetails;
