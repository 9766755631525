import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Snackbar from "../../ui-components/snackbar/snackbar";
import { GridLoader } from "../../components/skeletonloader/CallGridLoader";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { GridReadyEvent, CellClickedEvent } from "ag-grid-community";
import { CellClassParams } from "ag-grid-community";
import apiEndpoint from "../../core/apiend_point";
import { triggerApi } from "../../services/api-services";
import Calender from "../../assets/icons/calendar.svg";
import Cross from "../../assets/icons/cross-circle.svg";
import Chat from "../../assets/icons/chat-dot-round.svg";
import History from "../../assets/icons/history.svg";
import { PayloadProps } from "../../core/schema";
import { ERROR_UNABLE_TO_CONNECT, ERROR_WHILE_FETCHING_MESSAGE, NO_DATA_AVAILABLE, NO_RECORD_FOUND, SUCCESS } from "../../constants";
import Spinner from "../../ui-components/Spinner/Spinner";
import DismissPopup from "./DismissPopup";
import ScheduleConsulatation from "../../pages/clientInfo/schedule-consultation/ScheduleConsultation";
import moment from "moment";
import "./ToBeScheduleNonAdherence.css";
import { dateFormatter } from "../../services/helper";
import { gridOptions } from "../../utils/helper";

function ToBeScheduledGrid(props: any) {
  const [snackBarMsgs, setsnackBarMsgs] = useState<string>("");
  const [showSectionMsgs, setShowSectionMsgs] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSectionMsgErr, setShowSectionMsgErr] = useState<boolean>(false);
  const [infoErrMsg, setInfoErrMsg] = useState<string>("");
  const [confirmDismiss, setConfirmDismiss] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<string>("");
  const [showConsult, setShowConsult] = useState<boolean>(false);
  const history: any = useHistory();
  const [overlayNoRowsMessage, setOverlayNoRowsMessage] =
    useState(NO_DATA_AVAILABLE);
  const gridRef: any = useRef();
  const defaultColDef = {
    sortable: true,
    editable: false,
    flex: 1,
    floatingFilter: true,
    suppressMenu: true,
    wrapText: true,
    autoHeight: true,
    resizable: true,
  };

  const data: any =
    history && history.location.state && history.location.state.data
      ? history.location.state.data
      : {};

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const loadToBeScheduledCalls = {
    getRows(params: any) {
      let filterStatus = false;
      let apiUrl = `${apiEndpoint.toBeScheduled}?`;
      params.api.hideOverlay();
      const { startRow, endRow, filterModel, sortModel } = params.request;
      const modifiedStartRow = startRow;
      const modifiedEndRow = 49;
      Object.entries(filterModel).forEach(([filter, value]) => {
        if (filter === "start_date" || filter === "last_connected") {
          if (value && (value as { dateFrom: Date }).dateFrom) {
            const date = new Date((value as { dateFrom: Date }).dateFrom);
            const filterValue = dateFormatter(date);
            apiUrl += `${filter}=${filterValue}&`;
            filterStatus = true;
            setOverlayNoRowsMessage(NO_RECORD_FOUND);
          }
        } else if (filter === "week_number") {
          let weekValue = (value as { filter: number }).filter;
          if (isNaN(weekValue)) {
            weekValue = 0;
          }
          apiUrl += `${filter}=${weekValue}&`;
        }
          else {
          apiUrl += `${filter}=${(value as { filter: string }).filter}&`;
          filterStatus = true;
          setOverlayNoRowsMessage(NO_RECORD_FOUND);
        }
      });
      // sorting
      if (sortModel.length) {
        const { colId, sort } = sortModel[0];
        apiUrl += `order_by=${colId}&order=${sort}&`;
      }
      // pagination
      apiUrl += `limit=${modifiedEndRow}&offset=${modifiedStartRow}`;
      const apiObject: PayloadProps = {
        payload: {},
        method: "GET",
        headers: { "Content-Type": "application/json" },
        apiUrl: apiUrl,
      };

      triggerApi(apiObject)
        .then((response) => {
          if (response.status_code === 200 && response.status === SUCCESS) {
            if (response?.data?.length) {
              params.successCallback(response?.data, response?.count);
              params.api.hideOverlay();
              setTimeout(() => {
                setShowSectionMsgs(false);
                setLoading(false);
              }, 1000);
            } else {
              params.successCallback(response?.data, response?.count);
              params.api.showNoRowsOverlay();
            }
          } else {
            setShowSectionMsgs(true);
            setsnackBarMsgs(response?.message || ERROR_WHILE_FETCHING_MESSAGE);
            setLoading(false);
          }
        })
        .catch((err: object) => {
          console.log(err);
        });
    },
  };

  const onGridReady = (params: any) => {
    params.api.setServerSideDatasource(loadToBeScheduledCalls);
  };

  const updateGrid = () => {
    const gridApi = gridRef.current.api;
    onGridReady({ api: gridApi });
  };

  const clientsInfo = (data: any) => {
    history.push({
      pathname: `/clients/${data.client_id}`,
    });
  };

  const navigateToCallHistory = (params: any) => {
    history.push({
      pathname: `/clients/${params?.client_id}`,
      search: "?i=4",
    });
  };

  const showDismissPopUp = (params: any) => {
    setTaskId(params?._id);
    setConfirmDismiss(true);
  };

  const showScheduleConsultationPopUp = (params: any) => {
    setTaskId(params?._id);
    setShowConsult(true);
  };

  const handleChat = (params: any) => {
    const apiObject: PayloadProps = {
      payload: { user_id: params.client_id },
      method: "POST",
      headers: { "Content-Type": "application/json" },
      apiUrl: `${apiEndpoint.createConversation}`,
    };
    if (params?.conversation_id === null || params?.conversation_id.length <= 0) {
      setShowLoader(true);
      triggerApi(apiObject)
        .then(async (response: any) => {
          if (response.status_code === 200 && response.status === SUCCESS) {
            setShowLoader(false);
            history.push({
              pathname: `/chat/${response.data.conversation_id}`,
              state: {
                data: data,
              },
            });
          } else {
            setShowLoader(false);
            setInfoErrMsg(response?.message || ERROR_UNABLE_TO_CONNECT);
            setShowSectionMsgErr(true);
            setTimeout(() => {
              setShowSectionMsgErr(false);
              setInfoErrMsg("");
            }, 1500);
          }
        })
        .catch((err: object) => {
          console.log(err);
        });
    } else {
      history.push({
        pathname: `/chat/${params.conversation_id}`,
        state: {
          data: data,
        },
      });
    }
  };

  const QuickActionsRenderer = (props: any) => {
    const {
      onDismissClick,
      onScheduleClick,
      onChatClick,
      onHistoryCick,
      data,
    } = props;
    return (
      <div className="icon-wrapper">
        <img
          src={Calender}
          className="icon"
          title="Schedule"
          onClick={() => onScheduleClick(data)}
        ></img>
        <img
          src={Chat}
          className="icon"
          title="Chat"
          onClick={() => onChatClick(data)}
        ></img>
        <img
          src={History}
          className="icon"
          title="Call History"
          onClick={() => onHistoryCick(data)}
        ></img>
        <img
          src={Cross}
          className="deleteIcon"
          title="Dismiss"
          onClick={() => onDismissClick(data)}
        ></img>
      </div>
    );
  };

  const cellStyle = (params: CellClassParams) => {
    const cursor = "pointer";
    const color = "blue";
    const textDecoration = "underline";
    return {
      cursor: cursor,
      textDecoration: textDecoration,
      color: color,
    };
  };

  return (
    <div style={{ width: "100%", height: "100%" }} className="action">
      <Snackbar
        title="Success"
        appearance="success"
        message={snackBarMsgs}
        open={showSectionMsgs}
        close={() => setShowSectionMsgs(false)}
      />
      <Snackbar
        title="Error"
        appearance="error"
        message={infoErrMsg}
        open={showSectionMsgErr}
        close={() => setShowSectionMsgErr(false)}
      />

      {confirmDismiss ? (
        <DismissPopup
          dismiss={confirmDismiss}
          onCancel={setConfirmDismiss}
          setsnackBarMsgs={setsnackBarMsgs}
          setShowSectionMsgs={setShowSectionMsgs}
          loadClientRequests={updateGrid}
          taskId={taskId}
        />
      ) : null}

      {showConsult ? (
        <ScheduleConsulatation
          showConsult={showConsult}
          onCancel={setShowConsult}
          taskId={taskId}
          loadToBeScheduledCalls={updateGrid}
        />
      ) : null}

      {showLoader ? <Spinner /> : null}

      {loading ? (
        <GridLoader />
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: "70vh", width: "100%" }}
        >
          <AgGridReact
            frameworkComponents={{
              quickActions: QuickActionsRenderer,
            }}
            columnDefs={[
              {
                headerName: "Patient Name",
                field: "display_name",
                filter: "agTextColumnFilter",
                minWidth: 200,
                onCellClicked: function (params: any) {
                  return clientsInfo(params.data);
                },
                cellStyle: cellStyle,
              },
              {
                headerName: "Plan",
                field: "program_name",
                filter: "agTextColumnFilter",
                minWidth: 200,
              },
              {
                headerName: "Program Week",
                field: "week_number",
                filter: "agNumberColumnFilter",
                minWidth: 200,
                cellRenderer: (params: any) => {
                  return `Week ${params.data.week_number}`;
                },
              },
              {
                headerName: "Last Connected",
                field: "last_connected",
                filter: "agDateColumnFilter",
                sortable: false,
                minWidth: 200,
                cellRenderer: (params) => {
                  return params.data.last_connected
                    ? moment(params.data.last_connected)
                        .utc()
                        .format("Do MMM YYYY")
                    : "----";
                },
              },
              {
                headerName: "Task Start Date",
                field: "start_date",
                filter: "agDateColumnFilter",
                sortable: false,
                minWidth: 200,
                cellRenderer: (params) => {
                  return moment(params.data.start_date).utc().format("Do MMM");
                },
              },
              {
                headerName: "Quick Actions",
                minWidth: 150,
                sortable: false,
                filter: false,
                floatingFilter: false,
                cellRenderer: "quickActions",
                cellRendererParams: {
                  onScheduleClick: showScheduleConsultationPopUp,
                  onChatClick: handleChat,
                  onHistoryCick: navigateToCallHistory,
                  onDismissClick: showDismissPopUp,
                },
              },
            ]}
            ref={gridRef}
            pagination={true}
            paginationPageSize={7}
            defaultColDef={defaultColDef}
            rowModelType="serverSide"
            onGridReady={onGridReady}
            serverSideStoreType="partial"
            cacheBlockSize={49}
            animateRows={true}
            overlayNoRowsTemplate={overlayNoRowsMessage}
            gridOptions={gridOptions}
          />
        </div>
      )}
    </div>
  );
}

export default ToBeScheduledGrid;
