// import { AuthenticatedTemplate } from "@azure/msal-react";

// import { NavigationBar } from "./NavigationBar";

export const PageLayout = (props:any) => {
    
    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            {/* <NavigationBar /> */}
            {/* <br />
            <h5><div>Welcome to the Microsoft Authentication Library For React Tutorial</div></h5>
            <br /> */}
            {props.children}
            {/* <br />
            <AuthenticatedTemplate>
                <footer>
                    <div>How did we do? 
                        <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbR73pcsbpbxNJuZCMKN0lURpUMlRHSkc5U1NLUkxFNEtVN0dEOTFNQkdTWiQlQCN0PWcu" target="_blank"> Share your experience!</a>
                    </div>
                </footer>
            </AuthenticatedTemplate> */}
        </>
    );
};