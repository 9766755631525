// import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import "./signInButton.css";
import { PiButton } from "pixel-kit";

export const SignInButton = () => {
  const { instance } = useMsal();

  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);

  const handleLogin = (loginType: string) => {
    // setAnchorEl(null);

    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).then((response) => {
        // handle response
        console.log(response);
      });
    }
  };

  return (
    <div className="signin">
      <PiButton
        onClick={() => handleLogin("redirect")}
        key="loginRedirect"
        label="Login"
        appearance={"secondary"}
      />
    </div>
  );
};
