/* eslint-disable max-len */


const disableLogs = function() {
  const console: any = (function(oldCons) {
    return {
      log: () => { },
      info: () => { },
      warn: () => { },
      error: () => { },
      verbose: () =>{ },
    };
  })(window.console);
  window.console = console;
};
export default disableLogs;
export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};
export const checkInternetConnection =(()=>{
  if (navigator.onLine) {
  // do nothing
  } else {
    alert('No internet connection found. App is running in offline mode.');
  }
});
