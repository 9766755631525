import { BrowserRouter as Router } from "react-router-dom";
import { PageLayout } from "./components/PageLayout";
import  Home  from "./pages/Home";
import React, {useEffect} from 'react';

import "./styles/App.css";
import disableLogs, { clearCacheData } from "./utils/general";

const App = () => {
  useEffect(() => {
    // clearCacheData();
    //  disableLogs();
  })
    return (
    <Router>
      <PageLayout>
        <Home />
      </PageLayout>
    </Router>
  );
}

export default App;
