import Validations from '../../../core/validations/validations'

export const messages = {
    time: {
        dropdown: "Select the Timeslot", 
    },
    date:{
        required: "Select the Date"
    }
}  

export const ScheduleConsultSchema = Validations({
    time: 'dropdown',     
}, messages);
 