import * as yup from 'yup';

/**
     * Return the yup validate schema.
  * @param rules List of ruels object to generate the validation schema.
  * @param rulesMessage List of ruels custom messages object to display as error message.
  */

const Validations = (rules: object, rulesMessage: any) => {
  const yupRules = {};

  for (const [key, value] of Object.entries(rules)) {
    const validator = generateRules(value, rulesMessage[key] ? rulesMessage[key] : {});
    Object.assign(yupRules, {
      [key]: validator,
    });
  }
  const schema = yup.object().shape(yupRules);
  return schema;
}  


function generateRules(rule: string, rulesMessage: any) {
  const yupValidator = yup;

  let yupValidate: any;
  const subRules = rule.includes("|")
    ? rule.split("|")
    : rule.includes("required")
      ? ["required"]
      : [rule];
  subRules.forEach((subRule) => {
    if (subRule === "required") {
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().trim().required(rulesMessage[subRule])
          : yupValidate.concat(yupValidator.string().trim().required(rulesMessage[subRule]));
    } else if (subRule === "maxlimit") {
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.number().lessThan(100,rulesMessage[subRule]) 
          : yupValidate.concat(yupValidator.number().lessThan(100,rulesMessage[subRule]));  
    } else if (subRule === "string") {
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string()
          : yupValidate.concat(yupValidator.string());
    } else if (subRule === "object") {
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.object()
          : yupValidate.concat(yupValidator.object());
    } else if (subRule.startsWith("min")
           || subRule.startsWith("max")
           || subRule.startsWith("systoliclimit")
           || subRule.startsWith("hbac")
           || subRule.startsWith("sugar")
           || subRule.startsWith("thyroid")
           || subRule.startsWith("prolactin")
           || subRule.startsWith("cholestrol")
           || subRule.startsWith("hdlcholestrol") 
           || subRule.startsWith("acth")
           || subRule.startsWith("weight")
           || subRule.startsWith("bmi")
           || subRule.startsWith("height")) {   
      const [ruleType, length] = subRule.split(":");
      if (ruleType === "min") {
        yupValidate =
          typeof yupValidate != "object"
            ? yupValidator.string().min(Number(length), rulesMessage[ruleType])
            : yupValidate.concat(yupValidator.string().min(Number(length), rulesMessage[ruleType]));
      } else if (ruleType === "max") {
        yupValidate =
          typeof yupValidate != "object"
            ? yupValidator.string().max(Number(length), rulesMessage[ruleType])
            : yupValidate.concat(yupValidator.string().max(Number(length), rulesMessage[ruleType]));
      } else if (ruleType === "systoliclimit") {  
        yupValidate =
          typeof yupValidate != "object"
            ? yupValidator.number().min(40, rulesMessage[subRule]).max(300, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(40, rulesMessage[subRule]).max(300, rulesMessage[subRule]))  
      } 
      else if (ruleType === "hbac") {  
        yupValidate =
          typeof yupValidate != "object"
            ? yupValidator.number().min(2, rulesMessage[subRule]).max(30, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(2, rulesMessage[subRule]).max(30, rulesMessage[subRule]))  
      }  
      else if (ruleType === "sugar") {  
        yupValidate =
          typeof yupValidate != "object"
            ? yupValidator.number().min(20, rulesMessage[subRule]).max(700, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(20, rulesMessage[subRule]).max(700, rulesMessage[subRule]))  
      }  
      else if (ruleType === "thyroid") {  
        yupValidate =
          typeof yupValidate != "object"
            ? yupValidator.number().min(0.1, rulesMessage[subRule]).max(100, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(0.1, rulesMessage[subRule]).max(100, rulesMessage[subRule]))  
      }  
      else if (ruleType === "prolactin") {  
        yupValidate =
          typeof yupValidate != "object"
            ? yupValidator.number().min(1, rulesMessage[subRule]).max(1000, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(1, rulesMessage[subRule]).max(1000, rulesMessage[subRule]))  
      } 
      else if (ruleType === "cholestrol") {  
        yupValidate =
          typeof yupValidate != "object"
            ? yupValidator.number().min(50, rulesMessage[subRule]).max(600, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(50, rulesMessage[subRule]).max(600, rulesMessage[subRule]))  
      } 
      else if (ruleType === "hdlcholestrol") {  
        yupValidate =  
          typeof yupValidate != "object"
            ? yupValidator.number().min(5, rulesMessage[subRule]).max(200, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(5, rulesMessage[subRule]).max(200, rulesMessage[subRule]))  
      }   
      else if (ruleType === "acth") {  
        yupValidate =  
          typeof yupValidate != "object"
            ? yupValidator.number().min(2, rulesMessage[subRule]).max(400, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(2, rulesMessage[subRule]).max(400, rulesMessage[subRule]))  
      } 
      else if (ruleType === "weight") {  
        yupValidate =  
          typeof yupValidate != "object"
            ? yupValidator.number().min(1, rulesMessage[subRule]).max(500, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(1, rulesMessage[subRule]).max(500, rulesMessage[subRule]))  
      }  
      else if (ruleType === "bmi") {  
        yupValidate =  
          typeof yupValidate != "object"
            ? yupValidator.number().min(10, rulesMessage[subRule]).max(50, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(10, rulesMessage[subRule]).max(50, rulesMessage[subRule]))  
      }     
      else if (ruleType === "height") {  
        yupValidate =  
          typeof yupValidate != "object"
            ? yupValidator.number().min(45, rulesMessage[subRule]).max(300, rulesMessage[subRule])
            : yupValidate.concat(yupValidator.number().min(45, rulesMessage[subRule]).max(300, rulesMessage[subRule]))  
      }            
    } else if (subRule === "email") {
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().trim().email(rulesMessage[subRule])
          : yupValidate.concat(yupValidator.string().trim().email(rulesMessage[subRule]));
    } else if (subRule === "url") {
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().url(rulesMessage[subRule])
          : yupValidate.concat(yupValidator.string().url(rulesMessage[subRule]));
    } else if (subRule === "alphabets") {
      const emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'Only alphabet charectors allowed.';
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().matches(/^[a-zA-Z ]*$/, emsg)
          : yupValidate.concat(yupValidator.string().matches(/^[a-zA-Z ]*$/, emsg)); 
    } else if (subRule === "alphanumwithspace") {
      const emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'Only aplphanumeric and space charectors allowed.';
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().matches(/^[a-zA-Z0-9 _]*[a-zA-Z0-9][a-zA-Z0-9 _]*$/, emsg)
          : yupValidate.concat(yupValidator.string().matches(/^[a-zA-Z0-9 _]*[a-zA-Z0-9][a-zA-Z0-9 _]*$/, emsg));
    } else if (subRule === "password") {
      const emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'Password should satisfy below conditions';
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, emsg)
          : yupValidate.concat(yupValidator.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, emsg))
    }
    else if (subRule === "dropdown") {
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.object().required(rulesMessage[subRule])
          : yupValidate.concat(yupValidator.string().required(rulesMessage[subRule]));
    }
    else if (subRule === "date") {
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.object().required(rulesMessage[subRule])
          : yupValidate.concat(yupValidator.string().required(rulesMessage[subRule]));
    }  
    else if (subRule === "multiselect") {
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.array().required(rulesMessage[subRule])
          : yupValidate.concat(yupValidator.string().required(rulesMessage[subRule]));
    }
    else if (subRule === "confirmpassword") {
      const emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'The password does not match new password.Try again.';
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().oneOf([yupValidator.ref("password"), null], emsg)
          : yupValidate.concat(yupValidator.string().oneOf([yup.ref('password'), null], emsg));
    }
    else if (subRule === "name") {
      const emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'Name should contain atleast one character.';
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().matches(/^[a-zA-Z ]*$/, emsg)
          : yupValidate.concat(yupValidator.string().matches(/^[a-zA-Z ]*$/, emsg));
    }
    // else if (subRule === "parenthesis") {
    //   let emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'Name shouldnot contain these symbols.';
    //   yupValidate =
    //     typeof yupValidate != "object"
    //       ? yupValidator.string().matches(/^[^<>;]+$/, emsg)
    //       : yupValidate.concat(yupValidator.string().matches(/^[^<>;]+$/, emsg));
    // }
    else if (subRule === "phone") {
      const emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'Phonenumber error';
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().matches(/^[0-9-+()]*$/, emsg)
          : yupValidate.concat(yupValidator.string().matches(/^[0-9-+()]*$/, emsg));
    }
    else if (subRule === "zoomlink") {
      const emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'Enter valid zoom booking link';
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().matches(/[(http(s)?)://(www)?a-zA-Z0-9@:%._~#=]{2,256}[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?/gi, emsg)
          : yupValidate.concat(yupValidator.string().matches(/[(http(s)?)://(www)?a-zA-Z0-9@:%._~#=]{2,256}[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?/gi, emsg));
    }
    else if (subRule === "height") {
      const emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'Enter valid values';
      yupValidate =
        typeof yupValidate != "object"
          ? yupValidator.string().matches(/^([a-zA-Z0-9'"-.]+\s?)*$/gi, emsg)
          : yupValidate.concat(yupValidator.string().matches(/^([a-zA-Z0-9'"-.]+\s?)*$/gi, emsg));
    }
	else if (subRule === "link") {
		const emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'Enter valid link';
		yupValidate =
		  typeof yupValidate != "object"
			? yupValidator.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, emsg)
			: yupValidate.concat(yupValidator.string().matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, emsg));
	  }
    else if (subRule.startsWith("isDaynamic")) {
      const [ruleType, callBack] = subRule.split(":");
      if (ruleType ==='isDaynamic') {
        const emsg = (rulesMessage[subRule]) ? rulesMessage[subRule] : 'Enter valid data'; 
        yupValidate = typeof yupValidate != "object"
          ? yupValidator.string().test(
            "isDaynamic",
            emsg,
            async value => await dynamicMethod(value, callBack)
          )
          : yupValidate.concat(yup.string().test(
            "isDaynamic",
            emsg,
            async value => await dynamicMethod(value, callBack)
          ));
      }
    }
  });
  return yupValidate;
}

/**
  * Split a string into substrings using the specified separator and return them as an array.
  * @param value value that can be used to validate.
  * @param callBack A callback function should be defined here.
  */
function dynamicMethod(value: any, callBack: string) {
  if (callBack ==='validateUser') {
    return validateUser(value);
  }

  return true;
}


function validateUser(value: any) {
  return true;
}


export default Validations;
