import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { PiTypography } from "pixel-kit";

const WelcomeName = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");

  useEffect(() => {
    if (account && account.name) {
      // setName(account.name.split(" ")[0]);
      setName(account.name);
    } else {
      setName("");
    }
  }, [account]);

  if (name) {
    return (
      <div className="profile-name">
        <PiTypography component="h6">{`Welcome ${name}`}</PiTypography>
      </div>
    );
  } else {
    return null;
  }
};

export default WelcomeName;
