import { Box } from "@twilio-paste/core";
import styles from "../../styles";

interface SendMessageButtonProps {
  message: string;
  onClick: () => void;
}

const SendMessageButton: React.FC<SendMessageButtonProps> = (
  props: SendMessageButtonProps
) => {
  return (
    <Box style={styles.buttonWrapper}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          props.onClick();
        }}
      >
        <circle cx="16" cy="20" r="16" fill="#494E9D" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.6117 19.8221L10 13L10.932 19.1178L16.431 19.822L10.932 20.4822L10 26.6L25.6117 19.8221Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};

export default SendMessageButton;
