
import WelcomeName from "./WelcomeName";
import {SignInSignOutButton} from "./SignInSignOutButton";
import './NavBar.css'

const NavBar = () => {
    

    return (
        // <div>
            <div className="navheader">
                <div className="header_right"> 
                <WelcomeName />
                <SignInSignOutButton />
                </div>
                
            </div>
        // </div>
    );
};

export default NavBar;