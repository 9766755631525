import { useRef } from "react";
import { useMsal } from "@azure/msal-react";
import UserProfile from "../assets/userprofile.svg";
import { useDetectOutsideClick } from "./UseDetectOutsideClick";
import "./SignOutButton.css";
// import Spinner from "./Spinner/Spinner";

export const SignOutButton = () => {
  const { instance } = useMsal();
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  // const [loader, setLoader] = useState(false);

  const handleLogout = (logoutType: string) => {
    localStorage.clear();

    if (logoutType === "redirect") {
      // setLoader(true);
      setTimeout(() => {
        instance.logoutRedirect();
      }, 1000);
      // window.location.href = "/sign-in";
    }
  };

  return (
    <div className="signout">
      <img
        src={UserProfile}
        onClick={onClick}
        className="avatar"
        alt="profile"
      />
      <nav
        onClick={() => handleLogout("redirect")}
        ref={dropdownRef}
        className={`menu ${isActive ? "active" : "inactive"}`}
      >
        <ul>
          <li>
            <a href="#">Logout</a>
          </li>
        </ul>
      </nav>
      {/* {loader ? <Spinner /> : null} */}
    </div>
  );
};
