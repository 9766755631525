export const initialValues = {
	datepicker: '', 
	time: '', 
}    

export const dismissInitialValues = {
	description: '' 
}    

export const callRequestReportInitialValues = {
	fromDate: '',
	toDate: '',
	program: '',
	location: ''
}