import styled from "styled-components";
export const Spinn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  outline: 0;
  // background: rgba(255, 255, 255, 0.75);
  background: tansparent;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  // transform: translate(-50%, -50%);
  img {
    width: 350px;
  }
`;
export const SpinnerOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  width: 100%;
  height: 100%;
  background: #00000045;
  //background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 18px;
  transform: translate(-50%, -50%);
`;
