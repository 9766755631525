/* eslint-disable no-self-assign */
import axios from "../axios/index";
import { PayloadProps } from "../schema/schema";
// import { loginRequest, graphConfig } from "../authConfig";
// import { msalInstance } from "../index";
// const sampleJsonUrl= process.env.REACT_APP_SAMPLE_API_URL;
const apiBaseUrl = process.env.REACT_APP_API_URL;
const activitiesApiBaseUrl = process.env.REACT_APP_ACTIVITIES_API_URL;
const dietPlanApiBaseUrl = process.env.REACT_APP_API_DIET_PLAN;

// import json from '../core/tempApi'
/*  eslint "require-jsdoc": ["error", {
      "require": {
          "FunctionDeclaration": true,
          "ArrowFunctionExpression": true,
          "FunctionExpression": true
    }
}]  */
/**
 * @param {PayloadProps} params required params
 * @return {object} params//console.log(el);
 */
export async function triggerApi(params: PayloadProps) {
  return getResponse(params); // export async function triggerApiFake(response: any) {
}
/**
 * @param {PayloadProps} params required params
 * @return {object} params//console.log(el);
 */

/**
 * @param {PayloadProps} params required params
 * @return {object} params//console.log(el);
 */
export async function triggerActivitiesApi(params: PayloadProps) {
  return getActivitiesResponse(params);
}

/**
 * @param {PayloadProps} params required params
 * @return {object} params//console.log(el);
 */
export async function triggerSampleJsonApi(params: PayloadProps) {
  return getSampleJsonResponse(params);
}

/**
 * @param {PayloadProps} params required params
 * @return {object} params//console.log(el);
 */
export async function triggerTimeSlotApi(params: PayloadProps) {
  return getTimeSlotResponse(params);
}

/**
 * @param {any} params required params
 * @return {object} params
 */
async function getResponse(params: any) {
  const methodTypes = ["GET", "POST", "PUT", "DELETE"];

  if (methodTypes.indexOf(params.method.toUpperCase()) !== -1) {
    params.method = params.method;
  } else {
    params.method = "GET";
  }
  return await axios({
    method: params.method,
    url: params.nodeUrl ? params.nodeUrl : apiBaseUrl + params.apiUrl,
    data: params.payload,
    headers: params.headers,
    //  token:params.token
  })
    .then((res: any) => {
      const response = res.data;
      return response;
    })
    .catch((err: any) => {
      const error = err;
      return error;
    });
}

/**
 * @param {any} params required params
 * @return {object} params
 */


/**
 * @param {any} params required params
 * @return {object} params
 */
async function getActivitiesResponse(params: any) {
  // const bodyFormData = new FormData();
  // console.log(bodyFormData);
  // for (const key in params.payload) {obj.id
  const methodTypes = ["GET", "POST", "PUT", "DELETE"];

  if (methodTypes.indexOf(params.method.toUpperCase()) !== -1) {
    params.method = params.method;
  } else {
    params.method = "GET";
  }
  return await axios({
    method: params.method,
    url: activitiesApiBaseUrl + params.apiUrl,
    data: params.payload,
  })
    .then((res: any) => {
      const response = res.data;
      return response;
    })
    .catch((err: any) => {
      console.log(err, "Error");
    });
}

/**
 * @param {any} params required params
 * @return {object} params
 */
async function getSampleJsonResponse(params: any) {
  const methodTypes = ["GET", "POST", "PUT", "DELETE"];

  if (methodTypes.indexOf(params.method.toUpperCase()) !== -1) {
    params.method = params.method;
  } else {
    params.method = "GET";
  }
  return await axios({
    method: params.method,
    url: `http://localhost:3001/` + params.apiUrl,
    data: params.payload,
    headers: params.headers,
  })
    .then((res: any) => {
      const response = res.data;
      return response;
    })
    .catch((err: any) => {
     return err; 
    }); 
}

/**
 * @param {any} params required params
 * @return {object} params
 */
async function getTimeSlotResponse(params: any) {
  const methodTypes = ["GET", "POST", "PUT", "DELETE"];

  if (methodTypes.indexOf(params.method.toUpperCase()) !== -1) {
    params.method = params.method;
  } else {
    params.method = "GET";
  }
  return await axios({
    method: params.method,
    url: `http://localhost:3002/` + params.apiUrl,
    data: params.payload,
    headers: params.headers,
  })
    .then((res: any) => {
      const response = res.data;
      return response;
    })
    .catch((err: any) => {
      console.log(err, "Error");
    });
}

/**
 * @param {any} params required params
 * @return {object} params
 */
