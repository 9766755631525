import { PiModal, PiModalBody } from "pixel-kit";
import CloseIcon from "../../assets/cross.svg";
import "./modal.css";

interface FlareUpModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  flareUpReportData: any;
}

const FlareUpModal = ({
  showModal,
  setShowModal,
  flareUpReportData,
}: FlareUpModalProps) => {
  return (
    <PiModal isOpen={showModal}>
      <div>
        <div className="flare-up-modal-header">
          <h3>Flare up Report</h3>
          <button
            className="modal-close-button"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>
      </div>
      <PiModalBody>
        <div className="flare-up-report-section">
          <div className="flare-up-sub-section">
            <div>
              <h1>Reason</h1>
              <p>{flareUpReportData?.reason}</p>
            </div>
          </div>
          <div>
            <h1>Comments</h1>
            <p>{flareUpReportData?.comments || "No comments"}</p>
          </div>
        </div>
      </PiModalBody>
    </PiModal>
  );
};

export default FlareUpModal;
