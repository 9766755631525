import { Header } from "../..//ui-components/SignInSignOutButton";
import { Switch, Route, Redirect } from "react-router-dom";
import React, { lazy, Suspense, useState } from "react";
import "./rightcontainer.css";
import Spinner from "../../ui-components/Spinner/Spinner";
import ToBeScheduledGrid from "../../modules/dashboard/ToBeScheduledGrid";
import NonAdherenceGrid from "../../modules/dashboard/NonAdherenceGrid";
import FlareUpRequestGrid from "../../modules/dashboard/FlareUp";
const SignIn = lazy(() => import("../component/sign-in"));
const NotFound = lazy(() => import("../../ui-components/PageNotFound"));
const ClientInfo = lazy(() => import("../clientInfo/ClientInfo"));
const ChatTwillio = lazy(() => import("../../components/ChatTwillio"));
const Clients = lazy(() => import("../../modules/clients/Clients"));
const Dashboards = lazy(() => import("../../modules/dashboard/Dashboards"));
const ActionGrid = lazy(() => import("../../modules/dashboard/ActionGrid"));
const CallRequestGrid = lazy(() => import("../../modules/dashboard/CallRequestGrid"));
const Home = lazy(() => import("../../pages/Home"));
const DietList = lazy(() => import("../../pages/dietTemplate/DietList"));
const CreateTemplate = lazy(() => import("../dietTemplate/createtemplate/CreateTemplate"));
const DietDetails = lazy(() => import("../dietTemplate/dietDetails/DietDetails"));


function RightContainer() {
  const [count, setCount] = useState(0)
  function getData (data:any){
    setCount(data)
  }
  return (
    <div className="rightcontain">
      <Header />
      <Switch>
        <Suspense fallback={<Spinner />}>
          <Route path="/callback">
            <Home/>
            <Redirect exact to="/dashboard" />
          </Route>
          <Route exact path="/">
            <Home/>
            <Redirect exact to="/dashboard/callrequest" />
          </Route>
          <Route path="/chat/:id?">
            <ChatTwillio />
          </Route>
          <Route exact path="/clients">
            <Clients />
          </Route>
          <Route path="/clients/:id">
            <ClientInfo />
          </Route>
          <Route exact path="/diet">
            <DietList />
          </Route>
          <Route exact path="/diet/create/create-template">
            <CreateTemplate />
          </Route>
          <Route exact path="/diet/detail/:id">
            <DietDetails />
          </Route>
          <Route path="/dashboard">
            <Dashboards count={count} />
            <Redirect exact to="/dashboard/callrequest" />
          </Route>
          <Route path="/dashboard/to-be-scheduled"> 
            <ToBeScheduledGrid />
          </Route>
          <Route path="/dashboard/non-adherence"> 
            <NonAdherenceGrid />
          </Route>
          <Route path="/dashboard/flare-up">
            <FlareUpRequestGrid />
          </Route>
          <Route path="/dashboard/view-history"> 
            <ActionGrid />
          </Route>
          <Route path="/dashboard/callrequest">
            <CallRequestGrid getData={getData} />
          </Route>
          <Route path="signin">
            <SignIn />
          </Route>
          {/* <Route path="*">
          <NotFound />
        </Route> */}
        </Suspense>
      </Switch>
    </div>
  );
}

export default RightContainer;
