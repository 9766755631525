import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import { useEffect, useState } from "react";
import Sidebar from "./sidebar/sidebar";
import SignIn from "./component/sign-in";
import "./Home.css";
import { triggerApi } from "../services/api-services";
import RightContainer from "./rightcontainer/RightContainer";
import TokenExpiredModal from "../ui-components/TokenExpiredModal";
import { isTokenExpiredFalse } from "../core/localStorageService";
import { LicenseManager } from "ag-grid-enterprise";
import Unauthorized from "./Unauthorized";
import apiEndpoint from "../core/apiend_point";
import { PayloadProps } from "../core/schema";
import "../ui-components/NavBar.css";
import { SignInSignOutButton } from "../ui-components/SignInSignOutButton";
LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_LICENCE_KEY}` || "");

interface IAuthenticateResponse {
  _id: string,
  display_name: string,
  roles: string[],
}

function Home(props: any) {
  const [isTokenExpired, setTokenExpired] = useState(false);
  const [isUnauthorized, setIsUnauthorized]: any = useState(false);
  const [isAuthorized, setIsAuthorized]: any = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    document.addEventListener("tokenExpired", function (event: any) {
      setTokenExpired(event.detail);
    });
    if (account) {
      authorize();
    }
  }, [account, instance]);

  function authorize(){
    const apiObject: PayloadProps = {
      payload: {},
      method: "GET",
      headers: { "Content-Type": "application/json" },
      apiUrl: `${apiEndpoint.authentication}?roles=healthcoach`,
    };
    triggerApi(apiObject)
    .then((response) => {
      if(response.code === 'ERR_BAD_REQUEST') {
        if(response.response.data.message === 'Unauthorized') {
          setIsAuthorized(false);
          setIsUnauthorized(true);
        }
      } else {
        const data: IAuthenticateResponse = response.data;
  
        if (data.roles.includes("healthcoach")) {
          setIsAuthorized(true);
          setIsUnauthorized(false);
        } else {
          setIsUnauthorized(true);
          setIsAuthorized(false);
        }
      }
    })
    .catch((error: any) => {
      console.log(error, "Error");
    });
  }

  const handleModalClose = () => {
    document.dispatchEvent(isTokenExpiredFalse);
    window.location.reload();
    setTokenExpired(false);
  };
  return (
    <>
      {isTokenExpired && (
        <TokenExpiredModal
          isModalOpen={isTokenExpired}
          handleModalClose={handleModalClose}
        />
      )}
      <div className="home">
        {isUnauthorized && (
          <AuthenticatedTemplate>
            <div className="navheader">
              <div className="header_right">
                <SignInSignOutButton />
              </div>
            </div>
            <Unauthorized />
          </AuthenticatedTemplate>
        )}
        {isAuthorized && (
          <AuthenticatedTemplate>
            <div className="app">
              <Sidebar />
              <RightContainer />
            </div>
          </AuthenticatedTemplate>
        )}
        <UnauthenticatedTemplate>
          <SignIn />
        </UnauthenticatedTemplate>
      </div>
    </>
  );
}

export default Home;
